import React, { useEffect, useState, useContext } from 'react';
import { Grid, TextField} from '@material-ui/core';
import useStyles from '../Login/styles';  
import History from './History/History';
import SearchedItem from './History/SearchedItem';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';   
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
   
export const BusinessActivityHistory = () => {
  
 const classes = useStyles(); 
 const history = useHistory(); 
 const { baseURL, loginDetailsBag, dataReceiver, legalBusinessMemberAccountsBag, userRoleStatusesBag } = useContext(CartContext); 
 const [ loginDetails, setLoginDetails ] = loginDetailsBag;
 const [ legalBusinessMemberAccount, setLegalBusinessMemberAccount] = legalBusinessMemberAccountsBag; 
 const [ userRoleStatus, setUserRoleStatus] = userRoleStatusesBag;
 const [ open, setOpen] = React.useState(false);
 const [ currentPage, setCurrentPage] = useState(1);
 const [ productsPerPage, setProductsPerPage] = useState(100);
 const [ search, setSearch] = useState('');
 const dataDec  = dataReceiver;
 const [ loanHistory, setLoanHistory ] = React.useState([]);
 const [ businessHistory, setBusinessHistory] = useState([]);
 const [ sharedLoanHistory, setSharedLoanHistory ] = React.useState([]);
 let loanHistoryDetails = {};
 let sharedLoanHistoryDetails = {};  
 let appUserID = '';
 const checkObjType = history.location.loginState;
 let availableItems = {};
 let searchedItem = []; 



  if(typeof checkObjType !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);
          appUserID = loginDetails.organisations_id;

      }


 useEffect(()=> {

    setOpen(!open);

            let data = {companyID:appUserID};
                data = JSON.stringify(data);  
                    
                    
      const resp = axios.post(baseURL+'bridgeGetMyRechargeUnitRequests.php',data ).then(function (response) {

                      console.log(response.data)
                      response.data = dataDec(response.data);
                      searchedItem = response.data;
                      console.log(response.data)
                      setBusinessHistory(response.data);
                      setOpen(false); 
                                      
          });
     
   }, [])



  let filteredProducts = [];
  let currentProducts = [{}];
  
if(search != ''){

    filteredProducts = businessHistory.filter(product => {

      return product.requestNumber.toLowerCase().includes(search.toLowerCase());

    });
    
}


   if(filteredProducts.length > 1){
          
        currentProducts = filteredProducts;
       
   }
  

  return (
    
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

        <Grid container justify = "center" spacing={4}>
                <Grid item container justify = "center"  xs={12} sm={12} md={6} lg={6}>
                  
                  <TextField   label="Transaction Number"   variant="filled"  fullWidth color="primary"  onChange={e => setSearch(e.target.value)} size="small" /> {currentProducts.length} Results          

               </Grid>
        </Grid>     
           
              <Backdrop className={classes.backdrop} open={open} >
                       <Loader/>
              </Backdrop>
   
          <Grid container direction="column" justify="center">

                  <Grid item ></Grid>

                     <Grid container justify="center" spacing={2}>     
                                          
                       {businessHistory.map((historys)=> ( 
                        <Grid item xs={12} sm={6} md={4} lg={3} key={'history'+historys.id}>  
                            <History historys={historys} teamMember={legalBusinessMemberAccount} userRole={userRoleStatus}/> 
                            <br/>
                        </Grid>
                        ))} 
                            
                   </Grid>        
                       
                    <Grid item xs={false}  sm={3}/>
           
              </Grid>

  </main>

  );
}

export default BusinessActivityHistory;