import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import Loader from "../Loader/Loader";

// import styles & iconography
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import { CardHeader, TextField, InputAdornment } from "@material-ui/core";
import useStyles from "../Login/styles";
import Backdrop from "@material-ui/core/Backdrop";

// import Axios client
import axios from "axios";

export const LoanApplication = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const dataDec = dataReceiver;
  const [error, setError] = useState("");

  const [companyID, setCompanyID] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [repaymentPeriod, setRepaymentPeriod] = useState([]);
  const [loanType, setLoanType] = useState([]);
  let selectedRepaymentPeriod = "";
  let selectedLoanType = "";

  const [responseStatus, setResponseStatus] = useState("");

  // user details
  let loanStatusResponse = {};
  let borrowerCompanyID = loginDetails.organisations_id;
  let borrowerMoMoAccount = loginDetails.momoAccountNumber;

  useEffect(() => {
    setOpen(!open);
    setOpen(false);
  }, []);

  const handleRepaymentPeriodChange = (e) => {
    setRepaymentPeriod(e.target.value);
    selectedRepaymentPeriod = e.target.value;
  };

  const handleLoanTypeChange = (e) => {
    setLoanType(e.target.value);
    selectedLoanType = e.target.value;
  };

  const processLoanApplication = () =>
    history.push({
      pathname: "/",
      loanRequestState: loanStatusResponse,
    });

  const checkObjType = history.location.loginState;
  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const submitLoan = () => {
    setOpen(!open);

    let data = {
      companyID: companyID,
      loanAmount: loanAmount,
      repaymentPeriod: repaymentPeriod,
      loanType: loanType,
      membershipNumber: membershipNumber,
    };

    const res = axios
      .post(baseURL + "bridgeReceiveLoanTransaction.php", data)
      .then(function (response) {
        response.data = dataDec(response.data);
        let errorCodeMessage = response.data.code;

        if (errorCodeMessage == "201") {
          setError(response.data.res);
          setOpen(false);
          return 0;
        }

        if (errorCodeMessage == "200") {
          setResponseStatus(response.data);
          //  loanStatusResponse = responseStatus ;
          loanStatusResponse = response.data;

          processLoanApplication();
          setOpen(false);
        } else {
          let errorMessage = response.data.response;
          setError(errorMessage);
          setOpen(false);
        }
      });
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader title="Apply for a Loan" align="center" />

                {error && (
                  <>
                    <div
                      style={{ color: "red", align: "center" }}
                      align="center">
                      <b>{error}</b>
                    </div>
                    <br />
                  </>
                )}

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Company ID"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Company ID"
                    type="text"
                    id="compnayID"
                    name="companyID"
                    onChange={(e) => setCompanyID(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Customer Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Customer Number"
                    type="text"
                    id="customerNumber"
                    name="customerNumber"
                    onChange={(e) => setMembershipNumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Loan Type"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleLoanTypeChange}
                    value={selectedLoanType}>
                    <MenuItem value="loan-short">Short Term</MenuItem>
                    <MenuItem value="loan-long">Long Term</MenuItem>
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Loan Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Loan Amount"
                    type="text"
                    id="loanAmount"
                    name="loanAmount"
                    onChange={(e) => setLoanAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Repayment Period"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleRepaymentPeriodChange}
                    value={selectedRepaymentPeriod}>
                    <MenuItem value="3">3 Months</MenuItem>
                    <MenuItem value="6">6 Months</MenuItem>
                    <MenuItem value="12">12 Months</MenuItem>
                    <MenuItem value="18">18 Months</MenuItem>
                    <MenuItem value="36">36 Months</MenuItem>
                    <MenuItem value="60">60 Months</MenuItem>
                    <MenuItem value="72">72 Months</MenuItem>
                  </TextField>
                </Typography>

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => submitLoan()}>
                  {" "}
                  Apply Now
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />
    </main>
  );
};

export default LoanApplication;
