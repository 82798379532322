import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  TextField,
  Card,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import Loader from "../Loader/Loader";
import InputAdornment from "@material-ui/core/InputAdornment";
import CallIcon from "@material-ui/icons/Call";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import useStyles from "../Login/styles";
import { useHistory, Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";

export const BusinessAuthentication = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    baseURL,
    loginDetailsBag,
    dataReceiver,
    legalBusinessMemberAccountsBag,
    userRoleStatusesBag,
  } = useContext(CartContext);

  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [userRoleStatus, setUserRoleStatus] = userRoleStatusesBag;
  const businessUserAccountDetailsBag = {};
  let accountUsersRole = "";
  let isBusinessAccount = "";
  let usersRole = {};
  const [open, setOpen] = useState(false);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dataDec = dataReceiver;

  useEffect(() => {
    setLegalBusinessMemberAccount("");
    setUserRoleStatus("");
  }, []);

  const handleSubmit = async () => {
    setOpen(!open);
    let CompanyID = loginDetails.organisations_id;
    let data = { companyID: CompanyID, username: username, password: password };
    data = JSON.stringify(data);

    if (username == "") {
      setError("Username is required");
      setOpen(false);
      return 0;
    }

    if (password == "") {
      setError("Password is required");
      setOpen(false);
      return 0;
    }

    const res = await axios
      .post(baseURL + "bridgeLegalBusinesTeamMemberLogin.php", data)
      .then(function (response) {
        
          
        response.data = dataDec(response.data);
        let businessUserAccountDetailsBag = response.data;
        let usersRole = businessUserAccountDetailsBag.rights_id;
        setLegalBusinessMemberAccount(businessUserAccountDetailsBag);

        if (businessUserAccountDetailsBag.code == "200") {
          if (usersRole == 1) {
            accountUsersRole = "inputter";
            setUserRoleStatus(accountUsersRole);
          } else if (usersRole == 2) {
            accountUsersRole = "authorizer";
            setUserRoleStatus(accountUsersRole);
          }

          goToAuthenticate();
          setOpen(false);
        } else if (businessUserAccountDetailsBag.code != "200") {
          setError(businessUserAccountDetailsBag.description);
          setOpen(false);
        }
      });
  };

  const goToAuthorizer = () =>
    history.push({
      pathname: "/businessaccountactivity",
    });

  const goToInputter = () =>
    history.push({
      pathname: "/businessaccountactivity",
    });

  const goToAuthenticate = () =>
    history.push({
      pathname: "/userverification",
      businessUserState: businessUserAccountDetailsBag,
    });

  return (
    <main className={classes.content} style={{ backgroundColor: "ghostwhite" }}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={3}>
          {/*-------------------------------------------------------------------------
                                PAGE SIDE MENU START
-------------------------------------------------------------------------*/}

          <Grid item xs={false} sm={2} md={3} lg={4}></Grid>

          {/*------------------------------------------------------------------------------
                                   PAGE SIDE MENU END
----------------------------------------------------------------------------*/}

          {/*------------------------------------------------------------------------------
                                LOGIN FORM START
---------------------------------------------------------------------------------*/}

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                  <br />

                  {error !== "" ? (
                    <Typography variant="body1" gutterBottom align="center">
                      <span style={{ color: "red" }}> {error} </span>
                    </Typography>
                  ) : (
                    <Typography align="center" variant="body1" gutterBottom>
                      <b>ePayNet Business Authentication</b>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <hr />
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Enter Username"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CallIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Password"
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOpenIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Typography>

                <Backdrop className={classes.backdrop} open={open}>
                  <Loader />
                </Backdrop>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="inherit"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => handleSubmit()}
                >
                  {" "}
                  Login{" "}
                </Button>

                <br></br>
                <Typography variant="body1" gutterBottom>
                  <Button
                    component={Link}
                    to="#"
                    style={{
                      margin: "5px",
                      textTransaform: "none",
                      color: "black",
                    }}
                  >
                    {" "}
                    Forgot Password?
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/*------------------------------------------------------------------------------
                                LOGIN FORM END
---------------------------------------------------------------------------------*/}

          <Grid item xs={false} sm={2} md={3} lg={4}>
            {/* <Card>

                 
                  </Card>*/}
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default BusinessAuthentication;
