import React, { useEffect,useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles'; 
import { useHistory } from 'react-router-dom';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';

export const PayToCompany = () => {
  
       const classes = useStyles(); 
       const history = useHistory();
       const { loginDetailsBag, baseURL, dataReceiver }= useContext(CartContext);
       const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [ open, setOpen] = useState(false);
       const [ organisations, setOrganisations] = React.useState([]);
       const [ branches, setBranches] = React.useState([]);
       const [ selectedBank, setSelectedBank] = React.useState([]);
       const [ selectedBranch, setSelectedBranch] = React.useState([]);
       const [ amount, setAmount ] = React.useState([]);
       const [ reference, setReference ] = React.useState([]);
       const [ bankDetails, setBankDetails ] = React.useState([]);
       const [companyCategoriesID, setCompanyCategoriesID] = React.useState([]);
       const [selectedCompanyID, setselectedCompanyID] = React.useState([]);    
       const loanToPayDetailsBag = history.location.loanToPayState;
       let selectedCompanysID = '';
       let selectedBranchsID = '';
       let responseDetailsBag = {};
       let beneficiaryMomoDetailsBag = {};
       let appUser = loginDetails.organisations_id;
       let username = loginDetails.username;
       let companysID = '';
       let selectedOrganisation = {};
       const dataDec  = dataReceiver;

      


useEffect(()=> {
      
      setOpen(!open);

      const fetchOrganisations = async()=>{
          
          const res = await axios.get(baseURL+'bridgeGetDynamicCompanyCategories.php');
         
          res.data = dataDec(res.data);  

          setOrganisations(res.data);
          setOpen(false);      

      }

      fetchOrganisations();
     
   }, [])

 
   const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {

      setSelectedBank(event.target.value);
      selectedCompanysID = event.target.value;
      setOpen(!open);  

     let organisationDetails = organisations.filter(organisations => organisations.id === selectedCompanysID);
     let comapnyCategorysID = organisationDetails[0].companyCategories_id 
     

       setCompanyCategoriesID(comapnyCategorysID)  
       
  const fetchOrganisationNames = async()=>{
    
      const res = await axios.get(baseURL+'bridgeGetDynamicCategoryCompanies.php?companyCategoryID='+comapnyCategorysID+'&companyCategoryCategoryID='+selectedCompanysID);
          
        
          res.data = dataDec(res.data);
            
            
          setBranches(res.data); 
          setOpen(false);     


      }

      fetchOrganisationNames();

  };

   const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedBranch(event.target.value);     
        selectedBranchsID = event.target.value; 
       
        let organisation = branches.filter(branches => branches.companies_id === selectedBranchsID);
     
         selectedOrganisation = organisation[0].companies_id;
         setselectedCompanyID(selectedOrganisation);
       
       
  };


const submitLoan = () => {
  
  let dt = new Date();
  let seconds = dt.getSeconds();
  let minutes = dt.getMinutes();
  let hours = dt.getHours();  
  let year = dt.getFullYear();
  let month = dt.getMonth();
      month = month + 1;
  let day = dt.getDate();
   
     if(seconds < 10){
        seconds = '0'+seconds;
     }

     if(month < 10){
        month = '0'+month;
     }
 
     if(day < 10){
        day = '0'+day ;
     }
 
     if(minutes < 10){
        minutes = '0'+minutes ;
     }
 
     if(hours < 10){
        hours = '0'+hours ;
     }

 
   let headers = seconds +''+ minutes +''+hours+''+ day +''+ month +''+ year +''+username;
   let headerBody = parseInt(appUser) + parseInt(seconds);
   let body = headers+''+headerBody;
  

   let beneficiaryMomoDetailsBag = {companyCategoryCategories: selectedBank, companyCategory:companyCategoriesID, toCompany:selectedCompanyID, amount, reference, userKey:body
       };

     setBankDetails(beneficiaryMomoDetailsBag);
     goToTransactionConfirmation(beneficiaryMomoDetailsBag);
   
}

const goToTransactionConfirmation = (beneficiaryMomoDetailsBag) => history.push({ 
      
        pathname: '/confirmcompanytransaction',
        beneficiaryMomoState: beneficiaryMomoDetailsBag

});


  return (

      <main className = {classes.content}>
            <div className={classes.toolbar}/>   
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >

                             <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                             </Backdrop>

              <CardHeader title="Beneficiary Company Details"  align="center" />
                    <CardContent>
    
                    <Typography variant="h4" gutterBottom>                
                                 <TextField label="Select Organisation Category" variant="outlined"  fullWidth size="small" select
                                  onChange={handleBankChange}
                                  value={selectedBank}
                                  > 

                                    {organisations.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.categoryName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>
                           
                           <Typography variant="h4" gutterBottom>                
                              <TextField label="Select Organisations Name" variant="outlined" fullWidth size="small" select
                                onChange={handleBranchChange}
                                 value={selectedBranch}> 

                                    {branches.map((option) => (
                                    <MenuItem key={option.ccc_id} value= {option.companies_id} >
                                       {option.companyName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>
                         
                         
                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="Amount"
                                       name="Amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                           
                                      />
                                      </Typography>

                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Reference" variant="outlined" fullWidth size="small"
                                      placeholder="Reference"  
                                        type="text"
                                       id="Reference"
                                       name="Reference"
                                       onChange={(e)=>setReference(e.target.value)}
                                                                           
                                      />
                                      </Typography>            



                               
                  <Button className={classes.button} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitLoan()} > Pay
                  </Button>

                                 

 

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>




  </main>

  );
}



export default PayToCompany;