import React, { useContext } from "react";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory, Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import banner from "../../assets/tesBannerNew.png";
import loans from "../../assets/testBanner2New.png";

import global_styles from "../../index.module.css";
import card_styles from "../../components/ServiceCard/ServiceCard.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import section_styles from "../Masibambisane/Masibambisane.module.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faFileInvoice,
  faHandHoldingHand,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

export const Masibambisane = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);

  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const checkObjType = history.location.loginState;


  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {

    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const openActivations = () =>
    history.push({
      pathname: "/LenderActivation",
    });

  const openSavings = () =>
    history.push({
      pathname: "/saving",
    });

  const openSavingHistory = () =>
    history.push({
      pathname: "/savingsstatement",
    });

  const openBoleka = () =>
    history.push({
      pathname: "/lending",
    });

  const openRepay = () =>
    history.push({
      pathname: "/loanhistory",
    });

  const openSharing = () =>
    history.push({
      pathname: "/loansharing",
    });

  return (
    <main className={classes.content}>
      <div>
        <div className={styles.showcase}>
          <div className={styles.username + " " + global_styles.container}>
            <p>Welcome, {loginDetails.fullName}</p>
          </div>

          <div
            className={
              styles.slideshow__container + " " + global_styles.container
            }
          >
            <div className={styles.showcase__slider}>
              <div className={styles.slides}>
                <div className={styles.contents}>
                  <h2>Masibambisane Loans</h2>
                  <a
                    target="_blank"
                    className={styles.link}
                    href="http://salvtec.co.sz/support/knowledgebase.php?article=13"
                  >
                    Learn More{" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={styles.icon}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            section_styles.main__content__area + " " + global_styles.container
          }
        >
          <div className={section_styles.side__nav}>
            <Link to="#" onClick={() => openSavingHistory()}>
              Yonga / Savings history
            </Link>
            <Link to="#" onClick={() => openSharing()}>
              Share with contacts
            </Link>

            <div className={section_styles.help}>
              <FontAwesomeIcon icon={faQuestionCircle} />
              <a href="http://salvtec.co.sz/support/knowledgebase.php?category=15">
                Help
              </a>
            </div>
          </div>
          <div className={section_styles.service__cards}>
            <ServiceCard
              onClick={() => openActivations()}
              title={"Activations"}
              icon={
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className={card_styles.card__icon}
                />
              }
            />
            <ServiceCard
              onClick={() => openSavings()}
              title={"Yonga / Savings"}
              icon={
                <FontAwesomeIcon
                  icon={faHandHoldingHand}
                  className={card_styles.card__icon}
                />
              }
            />
            <ServiceCard
              onClick={() => openBoleka()}
              title={"Boleka / Request Loan"}
              icon={
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  className={card_styles.card__icon}
                />
              }
            />
            <ServiceCard
              onClick={() => openRepay()}
              title={"Repay Loan"}
              icon={
                <FontAwesomeIcon
                  icon={faReceipt}
                  className={card_styles.card__icon}
                />
              }
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Masibambisane;
