import React, { useEffect, useContext, useState } from 'react';
import {Card,  CardContent,  Typography, Grid, Button, MenuItem} from '@material-ui/core';
import useStyles from '../Login/styles';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


export const ConfirmInsurancePayment = () => {
	
   const classes = useStyles(); 
   const history = useHistory();
   const { loginDetailsBag, baseURL, dataReceiver }= useContext(CartContext);
   const [ loginDetails, setLoginDetails] = loginDetailsBag;
   const [ open, setOpen] = React.useState(false);
   const [ paymentPlatforms, setPaymentPlatforms] = useState([]);
   const [ responseStatus, setResponseStatus] = useState([]);
   const [ selectedPaymentPlatform, setSelectedPaymentPlatform] = useState([]);
   const [ amount, setAmount ] = React.useState([]);
   const [ reference, setReference ] = React.useState([]);
   const [ policyNumber, setPolicyNumber] = useState('');
   const [ selectedPolicy, setSelectedPolicy] = useState('');
   const [ sendToAccount, setSendToAccount] = useState('');
   const [ selectedPolicyDetails, setSelectedPolicyDetails] = useState([]);
   let selectedPlatform = '';
   let companyDetails = '';
   let companyName = '';
   let data2 = {};
   let data = {};
   let responseDetailsBag = {};
   const dataDec  = dataReceiver;
   const [ insurancePolicy, setInsurancePolicy] = useState([]);   
   const institutionPaymentDetailsBag = history.location.insuranceMemberDetailsState;
   const insurerDetails = history.location.insuranecState;
   const insuredName = history.location.clientsFullname;
   

    


        useEffect(()=> {

           setOpen(!open); 
           const fetchPaymentPlatform = async()=>{
          
                 const res = await axios.get(baseURL+'bridgeBringPaymentPlatforms.php');
            
                       res.data = dataDec(res.data); 
                       setPaymentPlatforms(res.data);  
                       setOpen(false);    

      }

      setInsurancePolicy(insurerDetails)  
      fetchPaymentPlatform();
     
   }, [])




const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
    



    return selectedPlatform;

  };  



  const handlePolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {

     setSelectedPolicy(event.target.value);

     let policyID = event.target.value;


      for (let i = 0; i < insurancePolicy.length; i++) { 

        let companiesID = insurancePolicy[i].id;

        if(policyID == companiesID){


          console.log(insurancePolicy[i])  
          let policyData = insurancePolicy[i];
          setSelectedPolicyDetails(policyData)

        }

   }

     let policyDetails = insurancePolicy.filter(insurancePolicy => insurancePolicy.id == policyID);
  
     setSendToAccount( policyDetails[0].momoAccNumber)

    
  };  

const account = institutionPaymentDetailsBag.IDNumber;

      const min = 100;
      const max = 999;
      const rand =  Math.floor(Math.random() * (max - min + 1) ) + min;
      const randAccountNumber = `${rand}${account}`;

  function d2h(d) {
  
    return d.toString(16);
  
  }
 
  function h2d (h) {
  
    return parseInt(h, 16);
  
  }
  
  function stringToHex (tmp) {
    let str = '',
        i = 0,
        tmp_len = tmp.length,
        c;
 
    for (; i < tmp_len; i += 1) {
        c = tmp.charCodeAt(i);
        str += d2h(c) + '';
    }
    return str;
}

const rand3hexAccountNumber = stringToHex(randAccountNumber);

 
  const submitPayment = () =>{

            setOpen(!open);
        let dt = new Date();
        let seconds = dt.getSeconds();
        let minutes = dt.getMinutes();   
        let insuranceID= institutionPaymentDetailsBag.insuranceID;
        let companyID = institutionPaymentDetailsBag.companyID;

        let membershipNumber = institutionPaymentDetailsBag.membershipNumber;
        let token = minutes+''+institutionPaymentDetailsBag.accountNumber+''+seconds;
        let appUser = institutionPaymentDetailsBag.userKey;
        let paymentPlatform = selectedPaymentPlatform;
        
        

let data = {

       saccoDataInsuranceID:insuranceID, 
       companyID:companyID,
       membershipNumber:membershipNumber,
       amount:amount, 
       reference:'epaynetApp'+companyID,
       localEftVendorID:paymentPlatform,
       fromAccount:loginDetails.momoAccountNumber,
       toAccount:sendToAccount

     }
 
  
    data = JSON.stringify(data);


    const res = axios.post(baseURL+'bridge_INS_ReceiveGtwInsuranceTransaction.php', data ).then(function (response) {  
      

       response.data = dataDec(response.data);
         
       if(response.data.success !== ''){
     
          responseDetailsBag = response.data ;
          goToPaymentStatus();
          setOpen(false);

        }else{

          alert('Connection error, Please try again later.'); 
          setOpen(false);
     }   
       
  });

} 



const goToPaymentStatus = () => history.push({ 
   
      pathname: '/localtransactionstatus',
      responseState: responseDetailsBag

});


	return (
  
      <main className = {classes.content}>

       <div className={classes.toolbar}/> 
           <Grid container direction="column">

                <Grid item></Grid>

                <Grid item container spacing={4}>
                  <Grid item xs={false} sm={2} md={3} lg={4}/>   
       
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                      <Card >

                            <Backdrop className={classes.backdrop} open={open} >
                                         <Loader/>
                            </Backdrop>
                                
                       <CardContent>
                         <Typography align="center" variant="body1">
                               <h5>Confirm Transaction</h5>
                         </Typography>
                         <hr />        
                          
                          <Typography variant="body1" gutterBottom>
                                Company <b>:{institutionPaymentDetailsBag.insuranceCompanyName}</b><br/>
                                Fullname <b>: {insuredName}</b><br/>
                                Membership # <b>: {institutionPaymentDetailsBag.membershipNumber}</b><br/>
                               
                                {selectedPolicyDetails.policyNumber ?

                                    <>
                                    </> 
                                
                                 :
                                 <>
                                   Policy Number: <b>{selectedPolicyDetails.policyNumber}</b><br/>
                                   Monthly Amount: <b>E{selectedPolicyDetails.monthlyPremium}</b><br/> 
                                 </>
                                    
                                 
                                    }
                          </Typography>
                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Select Policy" variant="outlined"  fullWidth    size="small" select
                                  onChange={handlePolicyChange}
                                  value={selectedPolicy}
                                  > 

                                    {insurancePolicy.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.policyName}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>


                          <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined" fullWidth size="small"
                                      placeholder="Amount"  
                                        type="text"
                                       id="Amount"
                                       name="Amount"
                                       onChange={(e)=>setAmount(e.target.value)}
                                                                           
                                      />
                          </Typography>

                          <Typography variant="body1" gutterBottom>                
                                 <TextField label="Payment Platform" variant="outlined"  fullWidth    size="small" select
                                  onChange={handleChange}
                                  value={selectedPaymentPlatform}
                                  > 

                                    {paymentPlatforms.map((option) => (
                                    <MenuItem key={option.id} value= {option.id} >
                                       {option.description}
                                   </MenuItem>

                                   ))}
                               </TextField>
                          </Typography>


                  <Button className={classes.loginButton} type="button" variant="contained" fullWidth color="primary" style ={{  margin: '5px',textTransaform:'none',backgroundColor:'#155054',color:'white'}} onClick={()=> submitPayment()} > Submit</Button>

                                  
                           </CardContent>
                         </Card>


                        </Grid>
          
                     <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>
    </main>

	);
}



export default ConfirmInsurancePayment;