import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import useStyles from "../Login/styles";
import Loader from "../Loader/Loader";

// import styles & iconography
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// import Axios client
import axios from "axios";

export const Deposits = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const dataDec = dataReceiver;

  // capture input fields
  const [companyID, setCompanyID] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [depositAmount, setDepositAmount] = useState("");

  const [responseStatus, setResponseStatus] = useState("");
  let depositStatusResponse = {};

  // user account details
  let fetchedBorrowerLenders = {};
  let borrowerCompanyID = loginDetails.organisations_id;
  let borrowerMoMoAccount = loginDetails.momoAccountNumber;

  useEffect(() => {
    setOpen(!open);
    
    const fetchCompanyID = async () => {
      const res = await axios.get(baseURL + "/");

      res.data = dataDec(res.data);

      setCompanyID(res.data);
      setOpen(false);
    };
    setOpen(false);
  //  fetchCompanyID();
  }, []);

  const goToDepositStatus = () =>
    history.push({
      pathname: "/depositStatus",
      depositRequestState: depositStatusResponse,
    });

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const makeDeposit = () => {
    setOpen(!open);


    setResponseStatus('Coming Soon...');
        //  loanStatusResponse = responseStatus ;
        depositStatusResponse = 'Coming Soon...'

        goToDepositStatus();
        setOpen(false);

        return 0;
    let data = {
      companyID: companyID,
      customerNumber: customerNumber,
      depositAmount: depositAmount,
    };

    const res = axios.post(baseURL + "/", data).then(function (response) {
      response.data = dataDec(response.data);
      let errorCodeMessage = response.data.code;

      if (errorCodeMessage == "201") {
        setError(response.data.res);
        setOpen(false);
        return 0;
      }

      if (errorCodeMessage == "200") {
        setResponseStatus(response.data);
        //  loanStatusResponse = responseStatus ;
        depositStatusResponse = response.data;

        goToDepositStatus();
        setOpen(false);
      } else {
        let errorMessage = response.data.response;
        setError(errorMessage);
        setOpen(false);
      }
    });
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <CardHeader
                  title="Deposit into your Transactional Account"
                  align="center"
                />

                {error && (
                  <>
                    <div
                      style={{ color: "red", align: "center" }}
                      align="center">
                      <b>{error}</b>
                    </div>
                    <br />
                  </>
                )}

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Company ID"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Company ID"
                    type="text"
                    id="companyID"
                    name="companyID"
                    onChange={(e) => setCompanyID(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Customer Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Customer Number"
                    type="text"
                    id="customerNumber"
                    name="customerNumber"
                    onChange={(e) => setCustomerNumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Deposit Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Deposit Amount"
                    type="text"
                    id="depositAmount"
                    name="depositAmount"
                    onChange={(e) => setDepositAmount(e.target.value)}
                  />
                </Typography>

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => makeDeposit()}>
                  {" "}
                  Continue
                  <FontAwesomeIcon
                    style={{ marginLeft: "10px" }}
                    icon={faArrowRight}
                  />
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default Deposits;
