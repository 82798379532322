import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

// import styles & iconography
import useStyles from "../Login/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CardHeader } from "@material-ui/core";

// import Axios client
import axios from "axios";

export const DepositStatus = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [paymentPlatforms, setPaymentPlatforms] = useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = useState([]);
  const [reference, setReference] = useState("");
  const dataDec = dataReceiver;

  const loanStatusResponse = history.location.loanRequestState;

  useEffect(() => {
    setOpen(!open);

    const fetchPaymentPlatform = async () => {
      const res = await axios.get(baseURL + "bridgeGetMobileMoneyVendors.php");

      res.data = dataDec(res.data);
      setPaymentPlatforms(res.data);
      setOpen(false);
    };

    fetchPaymentPlatform();
  }, []);

  const handlePaymentPlatformChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPaymentPlatform(event.target.value);
  };

  const checkObjType = history.location.loginState;
  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const submitingComplete = () =>
    history.push({
      pathname: "/loans",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />
          <Grid item xs={12} sm={8} md={6} lg={4}>
            {/*<Grid item xs={false} sm={4}/>
            
                        <Grid item xs={12} sm={4}>*/}
            <Card>
              <CardContent>
                <CardHeader title="Deposit Status" align="center" />

                <Typography variant="body1" gutterBottom>
                  {/* Status: <b>{loanStatusResponse.res}</b> */}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Lender Name: <b>xxxx</b> <br />
                  Customer Name: <b>xxxx</b> <br />
                  Customer No.: <b>xxxx</b> <br />
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Payment Platform"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handlePaymentPlatformChange}
                    value={selectedPaymentPlatform}>
                    {paymentPlatforms.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.vendorName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Reference"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Reference"
                    type="text"
                    id="fullName"
                    name="fullName"
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    color: "white",
                    textTransaform: "none",
                  }}
                  onClick={() => submitingComplete()}>
                  {" "}
                  Deposit Funds
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
      <div className={classes.toolbar} />
    </main>
  );
};

export default DepositStatus;
